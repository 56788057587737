import {BaseAdapter} from './BaseAdapter.js';

const COOKIE_KEY = 'a2u_postback';

/**
 * Sets a cookie with the specified name and value.
 * The cookie will expire in 1 year.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 */
function setCookie(name, value) {
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/;`;
}

/**
 * Retrieves the value of a cookie with the specified name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie, or null if the cookie does not exist.
 */
function getCookie(name) {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const [key, value] = cookie.trim().split('=');
    if (key === name) {
      return decodeURIComponent(value);
    }
  }
  return null;
}

/**
 * Adapter for handling A2U postback functionality.
 * Extends the BaseAdapter class.
 */
export class A2uPostbackAdapter extends BaseAdapter {
  postbackMap = new Map;

  /**
   * Saves the current URL's GET parameters to a cookie.
   */
  saveGetParamsToCookie() {
    const existsParams = this.getParamsFromCookie();

    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    setCookie(COOKIE_KEY, JSON.stringify({...existsParams, ...params}));
  }

  /**
   * Retrieves the GET parameters from the cookie.
   * @returns {Object} The GET parameters stored in the cookie.
   */
  getParamsFromCookie() {
    const params = getCookie(COOKIE_KEY);

    return params ? JSON.parse(params) : {};
  }

  /**
   * Initializes the adapter by saving GET parameters to a cookie if it's the first visit,
   * and populates the postback map from the configuration.
   */
  init() {
    this.saveGetParamsToCookie();

    this.postbackMap = (this.config?.postbacks || []).reduce((map, postback) => {
      if (!map.has(postback.actionType)) {
        map.set(postback.actionType, []);
      }

      map.get(postback.actionType).push(postback);

      return map;
    }, new Map);
  }

  /**
   * Parses bindings from URL search parameters.
   * @param {URLSearchParams} searchParams - The search parameters to parse.
   * @returns {Object} The parsed bindings.
   */
  parseBindings(searchParams) {
    const regex = /{([^}{]+)}/g;

    return Object.fromEntries([...searchParams].map(([key, value]) => {
      const match = regex.exec(value);
      return match ? [key, match[1]] : false;
    }).filter(Boolean));
  }

  /**
   * Calls a postback URL with the provided GET parameters.
   * @param {string} rawUrl - The raw URL to call.
   * @param {Object} getParams - The GET parameters to use in the URL.
   */
  async callPostback(rawUrl, getParams) {
    try {
      const url = new URL(rawUrl);

      const bindings = this.parseBindings(url.searchParams);

      if (!Object.values(bindings).every((key) => key in getParams)) {
        return;
      }

      for (const [key, placeholder] of Object.entries(bindings)) {
        url.searchParams.set(key, getParams[placeholder]);
      }

      await fetch(url.toString(), {
        method: 'GET',
      });
    } catch (e) {
      console.error('A2U postback error', e);
    }
  }

  /**
   * Emits an event and triggers the corresponding postbacks.
   * @param {string} event - The event to emit.
   */
  emit(event) {
    if (!this.postbackMap.has(event)) {
      return;
    }

    const getParams = this.getParamsFromCookie();

    const postbacks = this.postbackMap.get(event);

    for (const postback of postbacks) {
      this.callPostback(postback.url, getParams);
    }
  }
}
