<template>
  <q-dialog class="stripe-dialog z-max" full-width position="bottom">
    <q-card>
      <div class="row justify-end">
        <q-btn flat round icon="close" v-close-popup />
      </div>

      <q-card-section>
        <div ref="stripeCheckout" />

        <q-btn
          class="stripe-pay-btn full-width"
          @click="purchase"
          :disable="!isReady"
          :loading="isBusy"
          :label="btnLabel"
          text-color="white"
          unelevated
          no-caps
        />

        <template v-if="isTrial">
          <div class="stripe-trial-description q-mt-sm" v-text="trialDescription" />
          <div class="stripe-trial-description" v-text="firstPaymentDescription" />
        </template>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import humanizeDuration from 'humanize-duration';
import moment from 'moment';

export default {
  name: 'StripeDialog',

  props: ['a2u', 'apiKey', 'clientSecret', 'onComplete', 'product', 'endpoint'],

  data() {
    return {
      stripe: null,
      elements: null,
      isBusy: false,
      isReady: false,
    };
  },

  computed: {
    type() {
      return this.product.type;
    },
    isSubscription() {
      return this.type === 'subs';
    },
    isTrial() {
      return this.isSubscription && this.product.trialPeriodDays > 0;
    },
    moduleId() {
      return this.a2u.moduleId;
    },
    btnLabel() {
      const defaultValue = this.isSubscription ? 'Subscribe' : 'Pay';

      if (typeof this.a2u?.currentDiagramComonent?.interpretString !== 'function') {
        return defaultValue;
      }

      const key = this.isSubscription ? 'subscribe' : 'pay'

      return this.a2u?.currentDiagramComonent?.interpretString({
        isLocalizable: true,
        localeAlias: `${this.moduleId}.iap.payBtn.${key}`,
        value: defaultValue,
      });
    },
    trialDescription() {
      if (!this.isTrial) {
        return null;
      }

      const billingPeriodTitle = this.unpackPeriod(this.product.billingPeriod)
      const trialPeriodTitle = this.unpackPeriod(this.product.trialPeriod)

      const replaceData = {
        priceTitle: this.product.priceTitle,
        trialPeriodTitle,
        billingPeriodTitle,
      };

      let interpretedString = this.a2u?.currentDiagramComonent?.interpretString({
        isLocalizable: true,
        localeAlias: `${this.moduleId}.iap.web.sub.trial.trialDescription`,
        value: '{trialPeriodTitle} free trial, then {priceTitle}/{billingPeriodTitle}.',
      });

      if (Object.keys(replaceData).length) {
        for (const key in replaceData) {
          const regex = new RegExp(`\\{${key}\\}`, 'g');

          interpretedString = interpretedString.replace(regex, replaceData[key]);
        }
      }

      return interpretedString;
    },
    firstPaymentDescription() {
      if (!this.isTrial) {
        return null;
      }

      const duration = moment.duration(this.product.trialPeriod);
      const date = moment().add(duration).format('L');

      let interpretedString = this.a2u?.currentDiagramComonent?.interpretString({
        isLocalizable: true,
        localeAlias: `${this.moduleId}.iap.web.sub.trial.firstPaymentDescription`,
        value: 'Your first payment will be charged on {date}.',
      });

      const regex = new RegExp(`\\{date}`, 'g');

      interpretedString = interpretedString.replace(regex, date);

      return interpretedString;
    },
  },

  methods: {
    /**
     * Confirms the payment using Stripe.
     *
     * @param {Object} options - The options for confirming the payment.
     * @returns {Promise<boolean>} - Returns true if the payment is successful, otherwise false.
     */
    async _confirmPayment(options) {
      const {paymentIntent} = await this.stripe.confirmPayment(options);

      return paymentIntent?.status === 'succeeded';
    },

    /**
     * Confirms the setup intent using Stripe.
     *
     * @param {Object} options - The options for confirming the setup intent.
     * @returns {Promise<boolean>} - Returns true if the setup intent is successful, otherwise throws an error.
     * @throws {Error} - Throws an error if the setup intent fails.
     */
    async _confirmSetup(options) {
      const {setupIntent} = await this.stripe.confirmSetup(options);

      if (setupIntent?.status !== 'succeeded' || !setupIntent?.payment_method) {
        throw new Error('Failed to confirm setup intent');
      }

      const paymentMethodId = setupIntent.payment_method;

      await this.a2u?.context?.app?.client?.getAxios()?.post(`${this.endpoint}/stripe/createTrialSubscription`, {
        priceId: this.product.priceId,
        trialPeriodDays: this.product.trialPeriodDays,
        paymentMethodId,
      });

      return true;
    },

    /**
     * Initiates the purchase process.
     *
     * This method handles the purchase process by confirming the payment or setup intent
     * using Stripe. It sets the `isBusy` flag to prevent multiple submissions and resets
     * it once the process is complete. If the product has a trial period and is a subscription,
     * it confirms the setup intent; otherwise, it confirms the payment intent.
     *
     * @returns {Promise<void>}
     */
    async purchase() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      try {
        const options = {
          elements: this.elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: window.location.href,
          },
        };

        const result = this.product.trialPeriodDays > 0 && this.isSubscription
          ? await this._confirmSetup(options)
          : await this._confirmPayment(options);

        if (result) {
          this.onComplete();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isBusy = false;
      }
    },

    /**
     * Unpack period
     * @param period
     */
    unpackPeriod(period) {

      // No period
      if(!period) return undefined;

      // Extract the number (X) from the input
      const amount = parseInt(period.substring(1, period.length - 1), 10);

      let unit = String(period[period.length - 1]).toLowerCase();

      if (unit === 'm') {
        unit = 'mo';
      }

      if (!['d', 'w', 'mo', 'y'].includes(unit)) {
        throw new Error('Invalid period format');
      }

      return humanizeDuration(amount, {
        largest: 1,
        language: this.renderer?.currentLanguage,
        fallbacks: ['en'],
        units: [unit],
        unitMeasures: {
          y: 1,
          mo: 1,
          w: 1,
          d: 1,
          h: 1,
          m: 1,
          s: 1,
          ms: 1
        }
      });
    },
  },

  async created() {
    // Dynamically import Stripe.js
    const { loadStripe } = await import('@stripe/stripe-js');

    // Load Stripe.js
    const stripe = await loadStripe(this.apiKey);

    this.stripe = stripe;

    this.elements = stripe.elements({
      clientSecret: this.clientSecret,
      loader: 'always',
      appearance: {
        theme: 'stripe',
        labels: 'floating',
        variables: {
          colorText: '#6d6e78',
          colorTextPlaceholder: '#6d6e78',
        },
      },
    });

    const paymentElement = this.elements.create('payment', {
      paymentMethodOrder: ['google_pay', 'apple_pay', 'card'],
      layout: {
        type: 'accordion',
      },
    });

    paymentElement.on('ready', () => {
      this.isReady = true;
    });

    paymentElement.mount(this.$refs.stripeCheckout);
  },
}
</script>

<style lang="scss">
body.q-ios-padding .stripe-dialog {
  .q-dialog__inner {
    padding-top: 21px !important;

    & > div {
      max-height: calc(var(--screen-height, 100vh) - 21px) !important;
    }
  }
}

.q-btn.stripe-pay-btn {
  background-color: #31c48d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
  height: 64px;
}

.stripe-trial-description {
  color: #6d6e78;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
}
</style>
