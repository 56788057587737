import {BaseAdapter} from './BaseAdapter.js';

/**
 * TikTokAdapter extends the BaseAdapter to implement TikTok Pixel integration.
 * This adapter initializes the TikTok Pixel script and provides methods to emit events to TikTok Pixel.
 */
export class TikTokAdapter extends BaseAdapter {
  /**
   * Initializes the TikTok Pixel script asynchronously.
   * It injects the TikTok Pixel script into the document if it's not already present.
   * Upon successful injection, it initializes the pixel with the provided pixel ID from the adapter's configuration.
   * This method sets up the TikTok Pixel to track page views and other events as configured.
   * If an error occurs during the initialization process, it logs the error to the console.
   */
  init() {
    try {
      !function (w, d, t, cfg) {
        w.TiktokAnalyticsObject = t;
        var ttq = w[t] = w[t] || [];
        ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie'];
        ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } };
        for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
        ttq.instance = function (t) { for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]); return e };
        ttq.load = function (e, n) {
          var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
          ttq._i = ttq._i || {};
          ttq._i[e] = [];
          ttq._i[e]._u = i;
          ttq._t = ttq._t || {};
          ttq._t[e] = +new Date;
          ttq._o = ttq._o || {};
          ttq._o[e] = n || {};
          var o = document.createElement('script');
          o.type = 'text/javascript';
          o.async = !0;
          o.src = i + '?sdkid=' + e + '&lib=' + t;
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(o, a);
        };

        const pixelIds = cfg.pixelId.split(',').map((v) => v.trim());

        pixelIds.forEach(pixelId => {
          ttq.load(pixelId);
        });

        ttq.page();
      }(window, document, 'ttq', this.config);
    } catch (e) {
      console.error('TikTok pixel init error', e);
    }
  }

  /**
   * Emits an event to TikTok Pixel.
   * This method checks if the TikTok Pixel script is initialized and if not, attempts to initialize it.
   * It then emits the specified event along with an optional payload to TikTok Pixel.
   * If an error occurs during the event emission, it logs the error to the console.
   *
   * @param {string} event - The name of the event to emit.
   * @param {Object|null} payload - The payload of the event, if any.
   */
  emit(event, payload = null) {
    try {
      if (typeof window?.ttq?.track !== 'function') {
        this.init();
      }

      window.ttq.track(...[event, payload].filter(Boolean));
    } catch (e) {
      console.error('TikTok pixel emit error', e);
    }
  }
}
